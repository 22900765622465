import html from '../images/icons/html.svg'
import css from '../images/icons/css.svg'
import js from '../images/icons/js.svg'
import node from '../images/icons/node.svg'
import react from '../images/icons/react.svg'
import sass from '../images/icons/sass.svg'
import tux from '../images/icons/tux.svg'

export interface SkillData {
  icon: string
  title: string
  value: number
  color: string
  bg: string
}

export const skillsItems: SkillData[] = [
  { icon: html, title: 'HTML', value: 100, color: '#e44d26', bg: '#f1cbbb' },
  { icon: css, title: 'CSS', value: 100, color: '#264de4', bg: '#cad6f1' },
  { icon: js, title: 'JavaScript', value: 100, color: '#000000', bg: '#f7df1e' },
  { icon: node, title: 'Node JS', value: 100, color: '#026e00', bg: '#eaf5e9' },
  { icon: react, title: 'React JS', value: 100, color: '#61dafb', bg: '#464e51' },
  { icon: sass, title: 'Sass', value: 100, color: '#fff', bg: '#cc6699' },
  { icon: tux, title: 'Linux', value: 100, color: '#202020', bg: '#fffce5' },
].sort((a, b) => b.value - a.value)
