import * as React from 'react'
import Section from '../component/Section'
import AboutMe from '../component/AboutMe'
import NeedHelp from '../component/NeedHelp'
import Skills from '../component/Skills'
import { skillsItems } from '../data/skills'
import Page from '../Layout/Page'
import { graphql } from 'gatsby'

const IndexPage = ({
  data: {
    myPhoto: {
      childrenImageSharp: [myPhoto],
    },
    craped: {
      childrenImageSharp: [craped],
    },
    directus: { courses, project },
  },
}) => {
  return (
    <Page selected='Home' title='Home page'>
      <AboutMe
        next={{ fg: '#08aeea', bg: '#2af598' }}
        img={myPhoto}
        title='Hi my name is Ahmed Meftah'
        subtitle="I'm an entrepreneur and I teach python and web development."
      />
      <Section title='My courses' fg='#08aeea' bg='#2af598' nextfg='#8ec5fc' nextbg='#e0c3fc' items={courses} />
      <Section title='My projects' fg='#8ec5fc' bg='#e0c3fc' nextfg='#08aeea' nextbg='#2af598' items={project} />
      <Skills
        title='Technologies I like'
        fg='#08aeea'
        bg='#2af598'
        nextfg='#ff5acd'
        nextbg='#fbda61'
        items={skillsItems}
      />
      <NeedHelp
        title='You need my help ?'
        background={{ fg: '#ff5acd', bg: '#fbda61' }}
        wave={{ fg: '#1a1a1a', bg: '#363636' }}
        img={craped}
      />
    </Page>
  )
}

export default IndexPage

export const query = graphql`
  query {
    myPhoto: file(relativePath: { eq: "me.png" }) {
      childrenImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100)
      }
    }
    craped: file(relativePath: { eq: "craped.jpg" }) {
      childrenImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100)
      }
    }
    directus {
      project(filter: { featured: { _eq: true } }) {
        id
        name
        thumbnail {
          id
          title
          imageFile {
            childImageSharp {
              gatsbyImageData(width: 300, quality: 100)
            }
          }
        }
      }
      courses(filter: { featured: { _eq: true } }) {
        id
        name
        thumbnail {
          id
          title
          imageFile {
            childImageSharp {
              gatsbyImageData(width: 300, quality: 100)
            }
          }
        }
      }
    }
  }
`
