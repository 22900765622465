import * as React from 'react'
import Wave from '../Wave'
import { Link } from 'gatsby'
import Avatar from '../Avatar'
import { ImageDataLike } from 'gatsby-plugin-image'
import { background } from 'types'
import styled from 'styled-components'

interface NeedHelpProps {
  title: string
  img: ImageDataLike
  background: background
  wave: background
}

const HelpSection = styled.section<{ fg: string; bg: string }>`
  background: ${({ fg }) => fg};
  background-image: ${({ fg, bg }) => `linear-gradient(to right, ${fg}, ${bg})`} !important;
  min-height: 400px;
  font-size: 1.2rem;
  position: relative;

  .container {
    display: flex;
    h1 {
      font-size: 4rem;
    }
    .presentation {
      text-align: center;
      display: table-cell;
      margin: auto;
      vertical-align: middle;
      color: rgb(255, 255, 255);
    }

    a.btn {
      display: inline-block;
      padding: 0.75em;
      color: black;
      width: 70%;
      min-width: 200px;
      background: #8ed721;
      &:hover {
        background-color: #a8e74a;
      }
    }
  }

  @media screen and (max-width: 810px) {
    font-size: 1rem;

    .container {
      display: block;
      h1 {
        font-size: 2rem;
      }

      .avatar {
        display: block;
        border: 5px solid rgb(255, 255, 255);
        border-radius: 50%;
        width: 90%;
        margin: auto;
        max-width: 500px;
      }

      .presentation {
        display: block;
        margin: 2rem auto;
        width: 90%;
      }

      .btn {
        padding: 1em;
        width: 70%;
        min-width: 200px;
        background: $helpBtn;
        &:hover {
          background-color: lighten($helpBtn, 10);
        }
      }
    }
  }
`

function NeedHelp({ title, background, wave, img }: NeedHelpProps): React.ReactElement {
  return (
    <HelpSection fg={background.fg} bg={background.bg}>
      <div className='container bordred'>
        <Avatar img={img} />
        <div className='presentation'>
          <h1>{title}</h1>
          <Link to='/contact' className={'btn btn-primary'}>
            <i className='fas fa-phone-volume'></i> Contact Me
          </Link>
        </div>
      </div>
      <Wave fg={wave.fg} bg={wave.bg} />
    </HelpSection>
  )
}

export default NeedHelp
