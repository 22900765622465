import React from 'react'
import { Link } from 'gatsby'
import Wave from '../Wave'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import * as style from './aboutme.module.scss'

const Aboutme = ({ img, ...props }) => {
  return (
    <section className={style.aboutme} ref={props.element}>
      <div className={'container ' + style.container}>
        <GatsbyImage className={style.myImage} image={getImage(img)} alt='Ahmed Meftah' placeholder='blurred' />
        <div className={style.presentation}>
          <h1>{props.title}</h1>
          <p>{props.subtitle}</p>
          <div className={style.btns}>
            <Link to='/contact' className={style.btn + ' btn btn-secondary'}>
              <i className='fas fa-phone-volume'></i> Contact Me
            </Link>
            <Link to='/cv' className={style.btn + ' btn btn-secondary'}>
              <i className='far fa-eye'></i> View Curriculum vitæ
            </Link>
          </div>
        </div>
      </div>
      <Wave {...props.next} />
    </section>
  )
}

export default Aboutme
