import React from 'react'
import Wave from './Wave'
import CardItem from './CardItem'

function Courses({
  title = 'My cours',
  items = [],
  nextfg = '#8ec5fc',
  nextbg = '#e0c3fc',
  bg = '#e0c3fc',
  fg = '#8ec5fc',
}) {
  return (
    <section
      className='courses'
      style={{
        background: fg,
        backgroundImage: `linear-gradient(to right, ${fg}, ${bg})`,
      }}
    >
      <div className='container bordred'>
        <h1>{title}</h1>
        <div className='caroussel'>
          {items.map(e => (
            <CardItem key={e.id} {...e} />
          ))}
        </div>
      </div>
      <Wave fg={nextfg} bg={nextbg} />
    </section>
  )
}

export default Courses
