import * as React from 'react'
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image'

interface AvatarProps {
  img: ImageDataLike
  alt?: string
}

function Avatar({ img, alt = 'Ahmed Meftah' }: AvatarProps): React.ReactElement | null {
  const image = getImage(img)
  if (!image) return null
  return (
    <GatsbyImage
      style={{
        border: '10px solid rgb(255, 255, 255)',
        borderRadius: '50%',
        width: '40%',
        maxWidth: 300,
      }}
      image={image}
      alt={alt}
    />
  )
}

export default Avatar
