import * as React from 'react'
import Wave from './Wave'
import Gauge from './Gauge'

function Skills(props) {
  const title = props.title ? props.title : 'My Skills'
  const items = props.items ? props.items : []
  const fg = props.fg ? props.fg : '#08aeea'
  const bg = props.bg ? props.bg : '#2af598'
  const nextfg = props.nextfg ? props.nextfg : '#ff5acd'
  const nextbg = props.nextbg ? props.nextbg : '#fbda61'

  return (
    <section
      className='courses'
      style={{
        background: fg,
        backgroundImage: `linear-gradient(to right, ${fg}, ${bg})`,
      }}
    >
      <div className='container bordred'>
        <h1>{title}</h1>
        <div style={{ textAlign: 'center' }}>
          {items.map((e, k) => (
            <Gauge key={k} {...e} />
          ))}
        </div>
      </div>
      <Wave fg={nextfg} bg={nextbg} />
    </section>
  )
}

export default Skills
