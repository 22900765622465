import React from 'react'
import styled from 'styled-components'

interface GaugeProps {
  icon: string
  title: string
  value: number
  color: string
  bg: string
  size?: number
  strokeWidth?: number
}

const GaugeItem = styled.div`
  margin: 1rem;
  display: inline-block;
  width: 10rem;
  .gauge-content {
    position: relative;
  }
  .gauge-content .gauge {
    border-radius: 50%;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    position: relative;
    display: block;
  }
  .gauge-content .gauge .rail {
    stroke-linecap: round;
    stroke-linejoin: round;
  }
  .gauge-content .icon {
    display: inline-block;
    position: absolute;
    top: calc(50%);
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 3rem);
    border-radius: 50%;
  }
  h2 {
    font-size: 2rem;
    margin-top: 1rem;
  }
`

function Gauge({ size = 70, strokeWidth = 10, value = 100, bg, color, title, icon }: GaugeProps): React.ReactElement {
  const radius = (size - strokeWidth) / 2
  const viewBox = `0 0 ${size} ${size}`
  const dashArray = radius * Math.PI * 2
  const dashOffset = dashArray - (dashArray * value) / 100

  return (
    <GaugeItem className='gauge-item'>
      <div className='gauge-content'>
        <svg className='gauge' viewBox={viewBox}>
          <circle
            stroke-location='outside'
            className='rail'
            stroke={bg}
            fill={bg}
            r={radius}
            cx={size / 2}
            cy={size / 2}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1rem'
          />
          <circle
            stroke-location='outside'
            fill={bg}
            stroke={color}
            className='progress'
            data-target={value}
            r={radius}
            cx={size / 2}
            strokeWidth='0.3rem'
            strokeLinecap='round'
            strokeLinejoin='round'
            cy={size / 2}
            transform={`rotate(-90 ${size / 2} ${size / 2})`}
            style={{
              strokeDasharray: dashArray,
              strokeDashoffset: dashOffset,
            }}
          />
        </svg>
        <img className='icon' src={icon} alt={title} />
      </div>
      <h2>{title}</h2>
    </GaugeItem>
  )
}
export default Gauge
